import {
  CustomerStateLabel,
  CustomerStateName,
  ICustomerState,
  ICustomerStateCondition
} from './customer-types'


////////////////////////////////////////////////////////////
// Phase - Pre B
////////////////////////////////////////////////////////////
// Upload Prodboard file
export const CSC_UPLOAD_PRODBOARD_FILE = 'f'
export const INSERT_COMMENT_FROM_PRODBOARD = 'fa-02'
export const SEND_QUOTE_TO_CUSTOMER = 'fa-03'
////////////////////////////////////////////////////////////
// Phase - Pre A
////////////////////////////////////////////////////////////
export const REMIND_THE_CUSTOMER = 'fa-04'
export const REMIND_THE_CUSTOMER_AGAIN = 'preA-02'
export const HAS_CUSTOMER_RESPOND = 'preA-03'
////////////////////////////////////////////////////////////
// Phase - A
////////////////////////////////////////////////////////////
export const WHO_IS_NEXT_A = 'a-01'
export const CUSTOMER_WANTS_TO_CONTINUE = 'fa-05'
////////////////////////////////////////////////////////////
// Phase - B
////////////////////////////////////////////////////////////
export const WHO_IS_NEXT_B = 'b-00'
export const CONTROL_FRAME_WIDTHS = 'b-01'
export const REMINDER_LEGS = 'b-03'
export const ADD_INFO_APPLIANCE_MODELS = 'b-10'
export const SET_DOOR_INFO_APPLIANCES = 'b-11'
export const SET_TURNING_KNOBS_APPLIANCES = 'b-12'
export const CUTLERY_DIVIDERS_DECIDED = 'b-16'
////////////////////////////////////////////////////////////
// Phase - C
////////////////////////////////////////////////////////////
// The customer has approved the spec
export const WAITING_FOR_CUSTOMER_QUOTE_ACCEPT = 'c-18'
// The spec has been sent to the customer
export const CSC_SENT_QUOTE_TO_CUSTOMER = 'c-17'
////////////////////////////////////////////////////////////
// Phase - D
////////////////////////////////////////////////////////////
// Export the ordersheet pdf
export const CSC_EXPORT_ORDERSHEET = 'd-aa'
// Add a number to the project name
export const CSC_ADD_NUMBER_TO_PROJECT = 'd-ab'
export const CREATE_SLACK_CHANNEL_ID = 'd-ac'
// Drive: Create folders
export const CSC_DRIVE_CREATE_FOLDERS = 'd-ag'
// Insert in Kulladal's delivery schedule
export const CSC_INSERT_DELIVERY_SCHEDULE = 'd-ah'
// Insert in the carpentries delivery schedule
export const CSC_INSERT_CARPENTRY_DELIVERY_SCHEDULE = 'd-ai'
// Insert in the Agacco economy-sheet
export const CSC_AGACCO_ECONOMY_SHEET = 'd-aj'
// Insert the numbers into "Nya ekonomin"
export const CSC_NUMBERS_IN_NYA_EKONOMIN = 'd-ak'
// Send invoice 1 to the customer
export const CSC_SEND_INVOICE_1 = 'd-al'
// Anything else to order?
export const CSC_ANYTHING_ELSE_TO_ORDER = 'd-ay'
// Order special paint
export const CSC_ORDER_SPECIAL_PAINT = 'd-az'
////////////////////////////////////////////////////////////
// Phase - E
////////////////////////////////////////////////////////////
// The drawings must be completed by the CAD worker!
export const CAD_DRAWINGS_FIRST_VERSION = 'e-a'
// Send the drawings to the proofreader
export const CSC_PROOFREADER_SENT = 'e-b'
export const CAD_PROOFREADING_FINISHED = 'e-c'
export const CAD_UPDATED_AFTER_PROOFREADING = 'e-d'
// CAD-drawings: Sent to customer
export const CSC_CAD_SENT_TO_CUSTOMER = 'e-e'
// Kulladal must proofread the drawings'
export const CAD_CHECKED_BY_CUSTOMER = 'e-f'
// CAD-drawings: Updated after customer's input
export const CAD_UPDATED_AFTER_CUSTOMER = 'e-g'
// Kulladal must proofread the drawings
export const CSC_CAD_PROOFREAD_KULLADAL = 'e-h'
export const CSC_CAD_PROOFREAD_KULLADAL_UPDATED = 'e-h1'
export const CONFIRMED_MESSAGE_SENT = 'e-i'
////////////////////////////////////////////////////////////
// Phase - F
////////////////////////////////////////////////////////////
// Info to the customer about Steny/Nerostein measuring/delivery
export const CSC_CUSTOMER_STENY = 'fb'
// Lights: Equipment packed in the Kulladal storage
export const CSC_LIGHTS_PACKED = 'fc'
// Info to the customer: Date for delivery
export const CSC_CUSTOMER_DELIVERY_DATE = 'fd'
// Delivery info email to the customer
export const CSC_CUSTOMER_INFO_EMAIL = 'fe'
// Date of pickup confirmed by the carpentry
export const CSC_CARPENTRY_PICKUP = 'ff'
// Date of pickup confirmed by the transporter
export const CSC_TRANSPORTER_PICKUP = 'fg'
// Painting info email to the customer
export const CSC_PAINT_EMAIL_TO_CUSTOMER = 'fh'
// Fitting instruction PDF to the customer
export const CSC_FITTING_INSTRUCTION_PDF = 'fi'
export const CSC_INVOICE_2_OF_3 = 'fj'
export const KITCHEN_DELIVERED = 'fk'
////////////////////////////////////////////////////////////
// Phase - G
////////////////////////////////////////////////////////////
export const PAINTING_FINISHED = 'g-1'
// One more, used in Address List
export const SEND_CUPS_CONDITION = 'g-3'
////////////////////////////////////////////////////////////
// Phase - H
////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////
// Phase - I
////////////////////////////////////////////////////////////

// So we don't have to write this over and over
const oneDay: number = 60 * 60 * 24 * 1000

export class CustomerStateMap {
  public static readonly FIRST_STATE_LABEL: CustomerStateLabel = 'PRE_B'

  private readonly pCustomerStates: ICustomerState[] = [
    {
      state: {
        name: 'New quote requests',
        image: './assets/fas-1-new_quote.png',
        position: -2,
        state: 'PRE_B'
      },
      completed: false,
      conditions: [
        {
          type: 'BINARY',
          // ID was in other phase before. It is not correct, but leave as is.
          id: CSC_UPLOAD_PRODBOARD_FILE, // 'f', It should have been 'a-f'
          label: 'Upload file from the Ritprogram',
          completed: false,
          disabled: true,
          stateIds: ['PRODBOARD_FILE'],
          position: 0
        },
        {
          type: 'BINARY',
          // ID was in other phase before. It is not correct, but leave as is.
          id: INSERT_COMMENT_FROM_PRODBOARD, // 'fa-02'
          label: 'Insert comment from Prodboard backend',
          completed: false,
          // This should only be seen if the project is created from quote.
          stateIds: ['PRODBOARD_QUOTE'],
          position: 1
        },
        {
          type: 'BINARY',
          // ID was in other phase before. It is not correct, but leave as is.
          id: SEND_QUOTE_TO_CUSTOMER, //'fa-03',
          label: 'Send a quote to customer',
          advancedOptions: [
            {name: '', action: {lockPrice: true}}
          ],
          completed: false,
          position: 2
        }
      ],
      descriptionEnd: 'This phase ends when Kulladals has sent a initial quote to the customer'
    },
    {
      state: {
        name: 'Pending',
        image: './assets/fas0-pending.png',
        position: -1,
        state: 'PRE_A'
      },
      completed: false,
      conditions: [
        {
          type: 'BINARY',
          // ID was in other phase before. It is not correct, but leave as is.
          id: REMIND_THE_CUSTOMER, // 'fa-04',
          label: 'Remind the customer',
          completed: false,
          position: 0
        },
        {
          type: 'BINARY',
          id: REMIND_THE_CUSTOMER_AGAIN, // 'preA-02',
          label: 'Remind the customer again',
          completed: false,
          position: 1
        },
        {
          type: 'BINARY',
          id: HAS_CUSTOMER_RESPOND, // 'preA-03',
          label: 'Customer has replied',
          advancedOptions: [
            {name: '', action: {completeState: true}}
          ],
          completed: false,
          position: 2
        }
      ],
      descriptionEnd: 'This phase ends when the customer has returned and answered the quote'
    },
    {
      state: {
        name: 'Initial drafting',
        image: './assets/fas1-kontakt.png',
        position: 0,
        state: 'A'
      },
      completed: false,
      conditions: [
        {
          type: 'UNARY',
          id: WHO_IS_NEXT_A, // 'a-01'
          label: `Who's next?`,
          optional: true,
          advancedOptions: [
            {name: 'Me', action: {tagToOwner: 'me'}},
            {name: 'Customer', action: {tagToOwner: 'customer'}}
          ],
          completed: false,
          position: 0
        },
        {
          type: 'BINARY',
          // ID was in other phase before. It is not correct, but leave as is.
          id: CUSTOMER_WANTS_TO_CONTINUE, // 'fa-05'
          label: 'Confirmation needed! Do the customer want to continue?',
          completed: false,
          position: 1
        }
      ],
      descriptionEnd: 'This phase ends when the customer commits to making the order eventually'
    },
    {
      state: {
        name: 'Intensive drafting',
        image: './assets/fas2-skisser.png',
        position: 1,
        state: 'B'
      },
      completed: false,
      conditions: [
        {
          type: 'UNARY',
          id: WHO_IS_NEXT_B, // 'b-00'
          label: `Who's next?`,
          optional: true,
          advancedOptions: [
            {name: 'Me', action: {tagToOwner: 'me'}},
            {name: 'Customer', action: {tagToOwner: 'customer'}}
          ],
          completed: false,
          autoDeadlineSubtraction: oneDay * 100,
          position: 0
        },
        {
          type: 'BINARY',
          id: CONTROL_FRAME_WIDTHS, // 'b-01'
          label: 'Control all frame widths in the Ritprogram',
          completed: false,
          autoDeadlineSubtraction: oneDay * 100,
          position: 1
        },
        {
          type: 'BINARY',
          id: REMINDER_LEGS, // 'b-03'
          label: 'Reminder: Add legs if needed at corners/ends and stoves, in the Ritprogram',
          completed: false,
          stateIds: ['SKIRTING'],
          autoDeadlineSubtraction: oneDay * 75,
          position: 2
        },
        {
          type: 'BINARY',
          id: ADD_INFO_APPLIANCE_MODELS, // 'b-10'
          label: 'Add info about appliance models (in Mill and/or the Ritprogram)',
          completed: false,
          autoDeadlineSubtraction: oneDay * 75,
          position: 3
        },
        {
          type: 'BINARY',
          id: SET_DOOR_INFO_APPLIANCES, // 'b-11'
          label: 'Set "Sliding" or "Door-on-door" on appliances, in the Ritprogram',
          completed: false,
          stateIds: ['DOOR_ATTACHMENTS'],
          autoDeadlineSubtraction: oneDay * 75,
          position: 4
        },
        {
          type: 'BINARY',
          id: SET_TURNING_KNOBS_APPLIANCES, // 'b-12'
          label: 'Set turning knobs, if there should be any, in the Ritprogram',
          completed: false,
          stateIds: ['HANDLES'],
          autoDeadlineSubtraction: oneDay * 75,
          position: 5
        },
        {
          type: 'BINARY',
          id: CUTLERY_DIVIDERS_DECIDED, // 'b-16'
          label: 'Are cutlery dividers decided?',
          completed: false,
          stateIds: ['DRAWER_INSERTS'],
          autoDeadlineSubtraction: oneDay * 69,
          position: 6
        }
      ],
      descriptionEnd: 'This phase ends when the design and details are set, and the customer is ready for the final quotation'
    },
    {
      state: {
        name: 'Final quote',
        image: './assets/fas3-slutspec.png',
        position: 2,
        state: 'C'
      },
      completed: false,
      conditions: [
        {
          type: 'UNARY',
          id: 'c-01',
          label: 'Fittings to order? Add in "Settings / Appliances"',
          completed: false,
          options: ['Yes', 'No'],
          selection: null,
          autoDeadlineSubtraction: oneDay * 60,
          position: 0
        },
        {
          type: 'UNARY',
          id: 'c-02',
          label: 'Oven to order? Add in "Settings / Appliances"',
          completed: false,
          options: ['Yes', 'No'],
          selection: null,
          autoDeadlineSubtraction: oneDay * 60,
          position: 1
        },
        {
          type: 'UNARY',
          id: 'c-03',
          label: 'Hob to order? Add in "Settings / Appliances"',
          completed: false,
          options: ['Yes', 'No'],
          selection: null,
          autoDeadlineSubtraction: oneDay * 60,
          position: 2
        },
        {
          type: 'UNARY',
          id: 'c-04',
          label: 'Diswasher to order? Add in "Settings / Appliances"',
          completed: false,
          options: ['Yes', 'No'],
          selection: null,
          autoDeadlineSubtraction: oneDay * 60,
          position: 3
        },
        {
          type: 'UNARY',
          id: 'c-05',
          label: 'Microwaveoven to order? Add in "Settings / Appliances"',
          completed: false,
          options: ['Yes', 'No'],
          selection: null,
          autoDeadlineSubtraction: oneDay * 60,
          position: 4
        },
        {
          type: 'UNARY',
          id: 'c-06',
          label: 'Stove to order? Add in "Settings / Appliances"',
          completed: false,
          options: ['Yes', 'No'],
          selection: null,
          autoDeadlineSubtraction: oneDay * 60,
          position: 5
        },
        {
          type: 'UNARY',
          id: 'c-07',
          label: 'Extractor to order? Add in "Settings / Appliances"',
          completed: false,
          options: ['Yes', 'No'],
          selection: null,
          autoDeadlineSubtraction: oneDay * 60,
          position: 6
        },
        {
          type: 'UNARY',
          id: 'c-08',
          label: 'Fridge to order? Add in "Settings / Appliances"',
          completed: false,
          options: ['Yes', 'No'],
          selection: null,
          autoDeadlineSubtraction: oneDay * 60,
          position: 7
        },
        {
          type: 'UNARY',
          id: 'c-09',
          label: 'Freezer to order? Add in "Settings / Appliances"',
          completed: false,
          options: ['Yes', 'No'],
          selection: null,
          autoDeadlineSubtraction: oneDay * 60,
          position: 8
        },
        {
          type: 'UNARY',
          id: 'c-10',
          label: 'Sink/faucet to order? Add in "Settings / Appliances"',
          completed: false,
          options: ['Yes', 'No'],
          selection: null,
          autoDeadlineSubtraction: oneDay * 60,
          position: 9
        },
        {
          type: 'UNARY',
          id: 'c-11',
          label: 'Countertop, stainless steel, to order? Add in "Settings / Countertops"',
          completed: false,
          options: ['Yes', 'No'],
          selection: null,
          autoDeadlineSubtraction: oneDay * 60,
          position: 10
        },
        {
          type: 'UNARY',
          id: 'c-12',
          label: 'Countertop, stone, to order? Add in "Settings / Countertops"',
          completed: false,
          options: ['Yes', 'No'],
          selection: null,
          autoDeadlineSubtraction: oneDay * 60,
          position: 11
        },
        {
          type: 'UNARY',
          id: 'c-13',
          label: 'Countertop, wood, to order? Add in "Settings / Countertops"',
          completed: false,
          options: ['Yes', 'No'],
          selection: null,
          autoDeadlineSubtraction: oneDay * 60,
          position: 12
        },
        {
          type: 'UNARY',
          id: 'c-14',
          label: 'Anything else to order? ',
          completed: false,
          options: ['Yes', 'No'],
          selection: null,
          autoDeadlineSubtraction: oneDay * 60,
          position: 13
        },
        {
          type: 'BINARY',
          id: 'c-16',
          label: 'Input address / contact info to the customer',
          completed: false,
          stateIds: ['CUSTOMER_ADDRESS'],
          disabled: true,
          autoDeadlineSubtraction: oneDay * 75,
          position: 15
        },
        {
          type: 'BINARY',
          id: CSC_SENT_QUOTE_TO_CUSTOMER, // c-17
          label: 'Send the spec to the customer',
          completed: false,
          position: 16
        },
        {
          type: 'BINARY',
          id: WAITING_FOR_CUSTOMER_QUOTE_ACCEPT, // c-18
          label: 'Confirmation needed! The customer must approve the spec!',
          completed: false,
          position: 17
        }
      ],
      descriptionEnd: 'This phase ends when the customer accepts the final quotation'
    },
    {
      state: {
        name: 'Order',
        image: './assets/fas4-order.png',
        position: 3,
        state: 'D'
      },
      completed: false,
      conditions: [
        {
          type: 'BINARY',
          id: CSC_EXPORT_ORDERSHEET, // 'd-aa',
          label: 'Export the ordersheet PDF',
          completed: false,
          position: 1
        },
        {
          type: 'BINARY',
          id: CSC_ADD_NUMBER_TO_PROJECT, //'d-ab',
          label: 'Add a number to the project name',
          completed: false,
          position: 2
        },
        {
          type: 'BINARY',
          id: CREATE_SLACK_CHANNEL_ID, //'d-ac', // CREATE_SLACK_CHANNEL_ID
          label: 'Create Slack channel, invite carpentries and CAD workers',
          completed: false,
          position: 3
        },
        {
          type: 'BINARY',
          id: CSC_DRIVE_CREATE_FOLDERS, // 'd-ag',
          label: 'Create folder in Google Drive',
          completed: false,
          position: 4
        },
        {
          type: 'BINARY',
          id: CSC_INSERT_DELIVERY_SCHEDULE, // 'd-ah',
          label: 'Insert in Kulladal\'s delivery schedule',
          completed: false,
          position: 5
        },
        {
          type: 'BINARY',
          id: CSC_INSERT_CARPENTRY_DELIVERY_SCHEDULE, // 'd-ai',
          label: 'Insert in the carpentries delivery schedule',
          completed: false,
          position: 6
        },
        {
          type: 'BINARY',
          id: CSC_AGACCO_ECONOMY_SHEET, // 'd-aj',
          label: 'Insert in the Agacco economy-sheet',
          completed: false,
          stateIds: ['AGACCO'],
          position: 7
        },
        {
          type: 'BINARY',
          id: CSC_NUMBERS_IN_NYA_EKONOMIN, // 'd-ak',
          label: 'Insert the numbers into "Nya ekonomin"',
          completed: false,
          position: 8
        },
        {
          type: 'BINARY',
          id: CSC_SEND_INVOICE_1, // 'd-al',
          label: 'Send invoice 1 to the customer',
          completed: false,
          position: 9
        },
        {
          type: 'BINARY',
          id: CSC_ANYTHING_ELSE_TO_ORDER, // 'd-ay',
          label: 'Anything else to order?',
          completed: false,
          position: 21
        },
        {
          type: 'BINARY',
          id: CSC_ORDER_SPECIAL_PAINT, // 'd-az',
          label: 'Order special paint',
          completed: true,
          notApplicable: true,
          stateIds: ['SPECIAL_PAINT'],
          position: 22
        }
      ],
      descriptionEnd: 'This phase ends after processing the order and preparing for CAD-drawings'
    },
    {
      state: {
        name: 'CAD drawings',
        image: './assets/fas5-produktion.png',
        position: 4,
        state: 'E'
      },
      completed: false,
      conditions: [
        {
          type: 'BINARY',
          id: CAD_DRAWINGS_FIRST_VERSION, //'e-a',
          label: 'The drawings must be completed by the CAD worker!',
          completed: false,
          position: 0
        },
        {
          type: 'BINARY',
          id: CSC_CAD_PROOFREAD_KULLADAL, // 'e-h',
          label: 'Kulladal must proofread the drawings',
          completed: false,
          position: 1
        },
        {
          type: 'BINARY',
          id: CSC_CAD_PROOFREAD_KULLADAL_UPDATED, // 'e-h1',
          label: 'The drawings must be updated after Kulladal\'s check!',
          completed: false,
          position: 2
        },
        {
          type: 'BINARY',
          id: CSC_PROOFREADER_SENT, // 'e-b',
          label: 'Send the drawings to the proofreader',
          completed: false,
          position: 3
        },
        {
          type: 'BINARY',
          id: CAD_PROOFREADING_FINISHED, // 'e-c',
          label: 'The proofreading must be completed!',
          completed: false,
          position: 4
        },
        {
          type: 'BINARY',
          id: CAD_UPDATED_AFTER_PROOFREADING, // 'e-d',
          label: 'The drawings must be updated after proofreading!',
          completed: false,
          position: 5
        },
        {
          type: 'BINARY',
          id: CSC_CAD_SENT_TO_CUSTOMER, //'e-e',
          label: 'Send the drawings to the customer',
          completed: false,
          position: 6
        },
        {
          type: 'BINARY',
          id: CAD_CHECKED_BY_CUSTOMER, // 'e-f',
          label: 'Confirmation needed! The customer must approve the drawings!',
          completed: false,
          position: 7
        },
        {
          type: 'BINARY',
          id: CAD_UPDATED_AFTER_CUSTOMER, //'e-g',
          label: 'The drawings must be updated after customer\'s check!',
          completed: false,
          position: 8
        },
        {
          type: 'BINARY',
          id: CONFIRMED_MESSAGE_SENT, // 'e-i',
          label: 'Sent "CONFIRMED" message to the carpentry, including ordersheet and CAD drawings',
          completed: false,
          position: 9
        }
      ],
      descriptionEnd: 'This phase ends when the CAD-drawings have been accepted by both the customer and the carpentry'
    },
    {
      state: {
        name: 'In production',
        image: './assets/fas6-CAD.png',
        position: 5,
        state: 'F'
      },
      completed: false,
      conditions: [
        {
          type: 'BINARY',
          id: CSC_CUSTOMER_STENY, // 'fb',
          label: 'Info to the customer about Steny/Nerostein measuring/delivery',
          completed: false,
          stateIds: ['STENY'],
          position: 0
        },
        {
          type: 'BINARY',
          id: CSC_LIGHTS_PACKED, // 'fc',
          label: 'Pack LED-strips, spots, drivers, etc',
          completed: false,
          stateIds: ['LIGHTS'],
          position: 1
        },
        {
          type: 'BINARY',
          id: CSC_CUSTOMER_DELIVERY_DATE, // 'fd',
          label: 'Confirm approximate delivery date with the customer',
          completed: false,
          position: 2
        },
        {
          type: 'BINARY',
          id: CSC_CUSTOMER_INFO_EMAIL, // 'fe',
          label: 'Send email to the customer, about delivery info',
          completed: false,
          autoDeadlineSubtraction: oneDay * 7,
          position: 3
        },
        {
          type: 'BINARY',
          id: CSC_CARPENTRY_PICKUP, // 'ff',
          label: 'Confirm pickup date with the carpentry',
          completed: false,
          position: 4
        },
        {
          type: 'BINARY',
          id: CSC_TRANSPORTER_PICKUP, // 'fg',
          label: 'Confirm pickup date with the transporter',
          completed: false,
          position: 5
        },
        {
          type: 'BINARY',
          id: CSC_PAINT_EMAIL_TO_CUSTOMER, // 'fh',
          label: 'Send email to the customer, with info about painting',
          completed: false,
          stateIds: ['PAINTING_EMAIL'],
          position: 6
        },
        {
          type: 'BINARY',
          id: CSC_FITTING_INSTRUCTION_PDF, // 'fi',
          label: 'Send email to the customer, PDF with fitting instructions',
          completed: false,
          position: 7
        },
        {
          type: 'BINARY',
          id: CSC_INVOICE_2_OF_3, // 'fj',
          label: 'Send invoice 2 of 3 to the customer',
          completed: false,
          stateIds: ['INVOICE_TWO'],
          position: 8
        },
        {
          type: 'BINARY',
          id: KITCHEN_DELIVERED, // 'fk',
          label: 'The delivery should be completed',
          completed: false,
          position: 9
        }
      ],
      descriptionEnd: 'This phase ends when the kitchen has been delivered to the customer'
    },
    {
      state: {
        name: 'After delivery',
        image: './assets/fas7-leverans.png',
        position: 6,
        state: 'G'
      },
      completed: false,
      conditions: [
        {
          type: 'BINARY',
          id: PAINTING_FINISHED, // 'g-1',
          label: 'The painting should be completed',
          notApplicable: true,
          completed: false,
          stateIds: ['PAINT_FINISHED'],
          position: 0
        },
        {
          type: 'BINARY',
          id: 'g-2',
          label: 'Send invoice 3 of 3 to the customer',
          completed: false,
          stateIds: ['SEND_INVOICE'],
          autoDeadlineSubtraction: oneDay * -60,
          position: 1
        },
        {
          type: 'BINARY',
          id: SEND_CUPS_CONDITION,
          label: 'Send cups',
          completed: false,
          autoDeadlineSubtraction: oneDay * -180,
          position: 2
        }
      ],
      descriptionEnd: 'This phase ends after the kitchen, appliances and fixtures has been deliver, ' +
        'the kitchen is painted, the customer is happy and the invoices paid. At this point we close the project.'
    },
    {
      state: {
        name: 'Customer archive',
        image: './assets/archive-outline-kundarkiv.png',
        position: 7,
        state: 'H'
      },
      completed: false,
      conditions: [
        {
          /**
           * For some reason, I do not remember the archive needs a condition
           */
          type: 'BINARY',
          id: 'h-1',
          label: '',
          completed: false,
          position: 0
        }
      ],
      descriptionEnd: 'After everything is delivered, mounted and paid  the customer file is moved to the archive'
    },
    {
      state: {
        name: 'Drafts archive',
        image: './assets/archive-outline-skissarkivet.png',
        position: 8,
        state: 'I'
      },
      completed: false,
      conditions: [
        {
          /**
           * For some reason I do not remember the archive need a condition
           */
          type: 'BINARY',
          id: 'i-1',
          label: '',
          completed: false,
          comments: [],
          autoDeadlineSubtraction: 0,
          position: 0
        }
      ],
      descriptionEnd: `Archived projects (those from phase 1-2 who don't chose to continue)`
    }
  ]

  /**
   * Map containing all ICustomerStates indexed by their CustomerStateName's
   * label.
   */
  private pStateMap: Map<CustomerStateLabel, ICustomerState> = new Map()

  /**
   * Reduced map, only containing CustomerStateName as value instead
   * of whole ICustomerState object.
   */
  private pStateNameMap: Map<CustomerStateLabel, CustomerStateName> = new Map()

  /**
   * Map that allows quick access to all state conditions by their ID.
   */
  private conditionsMap: Map<string, ICustomerStateCondition> = new Map()

  constructor() {
    // Always sort states array by position. Just in case someone messed up
    // when creating a new state or modifying the array
    this.pCustomerStates
      .sort((a, b) =>
        a.state.position - b.state.position)

    // Create a Map for all states with their state label as key.
    // And create a simpler map, that will contain only CustomerStateName.
    // Also, create a map for state conditions for quick access.
    this.pCustomerStates.forEach((state: ICustomerState) => {
      this.pStateMap.set(state.state.state, state)
      this.pStateNameMap.set(state.state.state, state.state)
      state.conditions.forEach(c => {
        this.conditionsMap.set(c.id, c)
      })
    })
  }

  /**
   * Returns a copy (always a copy, this is a template, it cannot be modified),
   * of state recovered by label.
   * @param label State's label
   */
  public getState(label: CustomerStateLabel): ICustomerState {
    const state = this.pStateMap.get(label)
    return {
      ...state,
      conditions: state.conditions.map(c =>
        this.getCondition(c.id))
    }
  }

  /**
   * Returns a copy (always a copy, this is a template, it cannot be modified),
   * of condition recovered by ID.
   * @param cId Condition's ID
   */
  public getCondition(cId: string): ICustomerStateCondition {
    return {...this.conditionsMap.get(cId)}
  }

  /**
   * Returns all possible labels included in the states array
   */
  public get labels(): CustomerStateLabel[] {
    return this.pCustomerStates.map(s => s.state.state)
  }

  public get stateNameMap() {
    return this.pStateNameMap
  }
}

