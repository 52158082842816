import {IBeam, ICoordinate, ProdboardItem} from '../../services/prodboard-types'

export interface IPosition {
  xStart: number
  xEnd: number
  yStart: number
  zStart: number
  zEnd: number
}

export class CabinetMaxHeight implements IPosition {

  xStart: number
  xEnd: number
  yStart: number
  zStart: number
  zEnd: number

  /**
   * Since x, y, z all are places in the center we calculate like this if the cabinet have no rotation .
   * The start position for x is the x center minus the total length of the object divided by two
   * The end position for x is the x start position plus the total length of the object
   * The z positions is calculated the same way since there are no rotation
   */
  static getNoRotation(maxItem: IPosition, center: ICoordinate, dimensions: ICoordinate): void {
    maxItem.xStart = center.x - (dimensions.x / 2)
    maxItem.xEnd = maxItem.xStart + dimensions.x
    maxItem.zStart = center.z - (dimensions.z / 2)
    maxItem.zEnd = maxItem.zStart + dimensions.z
    maxItem.yStart = center.y - (dimensions.y / 2)
  }

  /**
   * If the cabinet have a rotation of 90 degrees we calculate like this.
   * The start position for x i the x center minus the cabinets length in z divided by to.
   * We do this because since it's rotated we want the thickness of the object.
   * We calculate the same way for the other points
   */
  static getNinetyRotation(maxItem: IPosition, center: ICoordinate, dimensions: ICoordinate): void {
    maxItem.xStart = center.x - (dimensions.z / 2)
    maxItem.xEnd = maxItem.xStart + dimensions.z
    maxItem.zStart = center.z - (dimensions.x / 2)
    maxItem.zEnd = maxItem.zStart + dimensions.x
    maxItem.yStart = center.y - (dimensions.y / 2)

    maxItem.xStart = center.x - (dimensions.z / 2)
    maxItem.xEnd = center.x + (dimensions.z / 2)
    maxItem.zStart = center.z - (dimensions.x / 2)
    maxItem.zEnd = center.z + (dimensions.x / 2)
    maxItem.yStart = center.y - (dimensions.y / 2)
  }

  /**
   * If the cabinet is rotated 180 degrees we calculate x the same way as 0 degrees.
   * For the z start position is now grater than the z end position. So we take the z center plus
   * the total length divided by two like before, but then we subtract the total length.
   * For the z end we add the total length divided by two to the center z to revers it.
   */
  static getOneEightyRotation(maxItem: IPosition, center: ICoordinate, dimensions: ICoordinate): void {
    maxItem.xStart = center.x - (dimensions.x / 2)
    maxItem.xEnd = maxItem.xStart + dimensions.x
    maxItem.zStart = (center.z + (dimensions.z / 2)) - dimensions.z
    maxItem.zEnd = center.z + (dimensions.z / 2)
    maxItem.yStart = center.y - (dimensions.y / 2)
  }

  /**
   * If the cabinet is rotated 270 degrees we calculate by doing a combination of 90 degrees and 180 degrees.
   */
  static getTwoSeventyRotation(maxItem: IPosition, center: ICoordinate, dimensions: ICoordinate): void {
    maxItem.xStart = center.x + (dimensions.z / 2) - dimensions.z
    maxItem.xEnd = center.x + (dimensions.z / 2)
    maxItem.zStart = center.z - (dimensions.x / 2)
    maxItem.zEnd = (center.z - (dimensions.x / 2)) + dimensions.x
    maxItem.yStart = center.y - (dimensions.y / 2)
  }

  static rotateIfNeeded(coordinate: ICoordinate, maxItem: IPosition, center: ICoordinate, dimensions: ICoordinate): void {
    if (coordinate.z === 1) {
      CabinetMaxHeight.getNoRotation(maxItem, center, dimensions)
    }
    if (coordinate.x === 1) {
      CabinetMaxHeight.getNinetyRotation(maxItem, center, dimensions)
    }
    if (coordinate.z === -1) {
      CabinetMaxHeight.getOneEightyRotation(maxItem, center, dimensions)
    }
    if (coordinate.x === -1) {
      CabinetMaxHeight.getTwoSeventyRotation(maxItem, center, dimensions)
    }
  }

  /**
   * Prodboard always uses the x-axis to define the length of a beam or cabinet.
   * So when we calculate the size and position we first check the rotation of the object.
   * We look at the objects position.direction.z and position.direction.x to determine the rotation.
   * z = 1: 0-degree rotation
   * x = 1: 90-degree rotation
   * z = -1: 180-degree rotation
   * x = -1: 270-degree rotation
   */
  public calculateCabinetMaxHeight(prodboardCabinet: ProdboardItem): number {
    /**
     * Collect height of beams here
     */
    let heights: number[] = []
    /**
     * We check if there are any beams in the room. If there are, we calculate the cabinets size and position.
     */
    if (prodboardCabinet.room.beams) {
      CabinetMaxHeight.rotateIfNeeded(prodboardCabinet.position.direction,
        this, prodboardCabinet.position.center, prodboardCabinet.dimensions)

      /**
       * We calculate the beams the same way as we do with the cabinets but since there can be more
       * than one beam in the room we have to check them all.
       */
      const beamsPositions: IPosition[] = prodboardCabinet.room.beams
        .map((b: IBeam) => {
          const beam: IPosition = {} as any
          // 0 degrees rotation
          CabinetMaxHeight.rotateIfNeeded(b.position.direction, beam, b.position.center, b.item.dimensions)
          return beam
        })

      /**
       * Finally we have the cabinets position and a list of beam positions.
       * Now we have to check if a beam and cabinet is in the same position in x or z.
       * For every beam we check if the beam x start i bigger or equal to the cabinet x start or
       * if it's smaller or equal to the cabinet x end AND if the beam x end is bigger or equal to
       * the cabinet x start or smaller or equal to the cabinet x end. This way we know if the beam
       * is over the cabinet in the x-axis. Then we do the same for the z-axis.
       * The last thing is to loop through the list of beams that are over the cabinet and for every
       * beam we check if it is lower than the cabinet maximum height and if it is we set the new value
       * for the cabinet maximum height.
       */
      heights = beamsPositions
        .filter((beam: IPosition) =>
          this.checkBetween(beam.xStart, beam.xEnd, this.xStart, this.xEnd)
          ||
          this.checkBetween(beam.zStart, beam.zEnd, this.zStart, this.zEnd)
        ).map((beam: IPosition) => beam.yStart)


    }
    /**
     * Return either the lowest beam or the room height
     */
    return Math.min(...heights, prodboardCabinet.room.dimensions.y)

  }

  /**
   * Check if value is between two values 4 <= x <= 5
   */
  private between(me: number, start: number, end: number): boolean {
    return me >= start && me <= end
  }

  /**
   * Check if both values are between two other values.
   */
  private checkBetween(first: number, second: number, start: number, end: number): boolean {
    return this.between(first, start, end) && this.between(second, start, end)
  }
}
